import React from "react";

const WidgetContext = React.createContext();

const sanitizeInput = (input) => (input ? +input.match(/\d+/)[0] : 680);

const MAX_TWO_COLUMNS_SIZE = 680;

function WidgetProvider({ data, height, config, children }) {
  const value = {
    widget: data,
    hasError: !Boolean(data),
    config,
    dimensions: {
      height: sanitizeInput(height),
    },
    MAX_TWO_COLUMNS_SIZE,
  };

  return (
    <WidgetContext.Provider value={value}>{children}</WidgetContext.Provider>
  );
}

function useWidget() {
  const context = React.useContext(WidgetContext);

  if (!context) {
    throw new Error("useWidget must be used within a WidgetProvider");
  }

  return context;
}

export { WidgetProvider, useWidget };

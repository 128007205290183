import React from "react";
import { createRoot } from "react-dom/client";

import { WidgetProvider } from "@Context/WidgetContext";
import { LocaleProvider } from "@Context/LocaleContext";
import App from "@/App";

import { fetchDSRConfiguration, fetchWidgetConfiguration } from "@/apiCalls";

export const init = async (config = {}) => {
  const elements = document.getElementsByClassName("trustworks-widget");

  const apiUrl = config.api_url || process.env.API_URL || "http://localhost:8000/v1.0";

  for (const element of elements) {
    let data = JSON.parse(element.getAttribute("data")) ?? (await fetchWidgetConfiguration(config.id, apiUrl));

    data.apiUrl = apiUrl;

    const lang = data.language || config.lang || "en";

    if (!data.error && !data.isPreview) {
      const dsrConfig = await fetchDSRConfiguration(config.id, apiUrl, lang);

      data.dsrConfig = dsrConfig;
    }

    const locale = await import(/* webpackInclude: /\.json$/ */ `./i18n/${lang}.json`);

    const MainApp = () => (
      <LocaleProvider locale={locale} lang={lang}>
        <WidgetProvider data={data} height={element.style.height} config={config}>
          <App step={element.getAttribute("step")} />
        </WidgetProvider>
      </LocaleProvider>
    );

    const root = createRoot(element);

    root.render(<MainApp />);
  }
};

/**
 * Config Options
 *
 * id: uuid of the widget
 * api_url: api url of the widget
 * lang: language of the widget
 * withCaptcha: boolean to enable captcha
 * override: boolean to override the default font (currently uses google font)
 * data: data to be passed to the widget
 * step: step of the widget(for preview only)
 */

if (process.env.NODE_ENV === "development") {
  console.log("Running in development mode");
  init({
    id: process.env.WIDGET_ID,
    withCaptcha: false,
    overrideFont: false,
  });
}

import axios from "axios";

export const fetchWidgetConfiguration = async (uuid, apiUrl) => {
  try {
    const API_URL =
      apiUrl || process.env.API_URL || "http://localhost:8000/v1.0";

    const response = await axios.get(`${API_URL}/widgets/config/info/${uuid}`);

    return response.data;
  } catch (error) {
    if (error.response) {
      if (error.response.status === 404) {
        return {
          error: {
            message: error.response.data,
            code: 404,
          },
        };
      }

      return error;
    }

    throw new Error("Could not reach the API");
  }
};

export const fetchDSRConfiguration = async (uuid, apiUrl, lang) => {
  try {
    const API_URL =
      apiUrl || process.env.API_URL || "http://localhost:8000/v1.0";

    const response = await axios.get(
      `${API_URL}/dsr-configs/public${lang ? `?lang=${lang}` : ""}`,
      {
        headers: {
          "x-ql-widget-uuid": uuid,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error("Could not reach the API");
  }
};

export const createNewDSR = async (data, apiUrl, uuid, withCaptcha) => {
  try {
    const API_URL =
      apiUrl || process.env.API_URL || "http://localhost:8000/v1.0";

    const filesInputs = data.subject_attributes.filter((entry) =>
      entry.type.includes("file")
    );
    
    const processedData = {
      ...data,
      subject_attributes: data.subject_attributes.map((input) =>
        input.type.includes("file")
          ? {
              type: input.type,
              value: input.value.at(0).name, // considers only the first file
              label: input.name,
            }
          : {
              type: input.type,
              value: input.value,
              label: input.name,
            }
      ),
    };

    const formData = new FormData();

    filesInputs.forEach((input) => {
      const files = input.value;
      files.forEach((file) => formData.append("files", file));
    });

    formData.append("dsr", JSON.stringify(processedData));

    const response = await axios.post(`${API_URL}/dsr`, formData, {
      params: {
        with_captcha: withCaptcha,
      },
      headers: {
        "Content-Type": "multipart/form-data",
        "x-ql-widget-uuid": uuid,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error("Could not reach the API");
  }
};

import React from "react";

const LocaleContext = React.createContext();

function LocaleProvider({ locale, lang, children }) {
  return (
    <LocaleContext.Provider value={{ ...locale, lang }}>
      {children}
    </LocaleContext.Provider>
  );
}

function useLocale() {
  const context = React.useContext(LocaleContext);

  if (!context) {
    throw new Error("useLocalte must be used within a LocaleProvider");
  }

  return context;
}

export { LocaleProvider, useLocale };

import React, { Suspense } from "react";
import { ThemeProvider } from "styled-components";

import { useWidget } from "@Context/WidgetContext";
import { useLocale } from "@Context/LocaleContext";
import Spinner from "./components/Spinner";

const PrivacyCenter = React.lazy(() => import("@Components/PrivacyCenter"));

function App({ step }) {
  const { hasError, widget, MAX_TWO_COLUMNS_SIZE, config } = useWidget();

  const { loadWidgetError } = useLocale();

  if (hasError || widget.error) {
    return (
      <div
        style={{
          padding: "70px 0",
          textAlign: "center",
          fontSize: "18px",
        }}
      >
        <p>{widget.error.message ?? loadWidgetError}</p>
      </div>
    );
  }

  return (
    <Suspense fallback={<Spinner color="black" size="large" />}>
      <ThemeProvider
        theme={{
          ...widget.brand,
          overrideFont: config.overrideFont,
          maxSize: MAX_TWO_COLUMNS_SIZE,
        }}
      >
        <PrivacyCenter step={step} />
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
